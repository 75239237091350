import React from 'react';
import './reset.css';
import './global.scss';
import SEO from './Seo/seo';

const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      <div className="layout">{children}</div>
    </>
  );
};

export default Layout;
